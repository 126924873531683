// Tools used for palette creation
// https://material.io/inline-tools/color/
// https://material.io/tools/color/#!/?view.left=0&view.right=1&primary.color=159bd7&secondary.color=3f9c46
// refernce theme structure: https://material-ui.com/customization/default-theme/
const theme = {
  typogrphaphy: {
    fontSize: 16,
    normal: {
      fontFamily: 'Prelo-Book',
      fontWeight: 'normal',
      fontStyle: 'normal',
    },
    italic: {
      fontFamily: 'Prelo-BookItalic',
      fontWeight: 'normal',
      fontStyle: 'normal',
    },
    bold: {
      fontFamily: 'Prelo-Bold',
      fontWeight: 'normal',
      fontStyle: 'normal',
    },
    light: {
      fontFamily: 'Prelo-Light',
      fontWeight: 'normal',
      fontStyle: 'normal',
    },
    truncateEllipsis: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  },
  palette: {
    common: {
      white: '#fff',
      black: '#000',
    },
    blue: {
      main: '#009CDE',
      light: '#00B2FF',
      medium: '#027FB6',
      dark: '#0274A6',
      contrastText: '#fff',
    },
    green: {
      main: '#3F9C35',
      light: '#81E678',
      dark: '#61AB59',
      contrastText: '#fff',
    },
    grey: {
      700: '#323435',
      600: '#63666A',
      500: '#888B8D',
      400: '#AFB2B4',
      300: '#D2D5D7',
      200: '#EAECEE',
      100: '#F5F6F6',
    },
    informational: {
      gold: '#A2A569',
      orange: '#E87722',
      red: '#E40046',
      purple: '#9F5CC0',
      teal: '#34A798',
      yellow: '#F1B434',
    },
  },
  breakpoints: {
    sm: '@media (min-width: 576px)',
    md: '@media (min-width: 768px)',
    lg: '@media (min-width: 992px)',
    xl: '@media (min-width: 1200px)',
  },
  transitions: {
    create: createTransition,
    easing: {
      easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
      easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
      easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
      sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
    },
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      standard: 300,
      complex: 375,
      enteringScreen: 225,
      leavingScreen: 195,
    },
  },
} as const

function createTransition(
  properties: string | string[] = 'all',
  duration: keyof typeof theme.transitions.duration = 'short',
  easing: keyof typeof theme.transitions.easing = 'easeInOut'
) {
  return Array.prototype
    .concat(properties)
    .map(
      property =>
        `${property} ${theme.transitions.duration[duration]}ms ${theme.transitions.easing[easing]}`
    )
    .join(', ')
}

export { theme }
