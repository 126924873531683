import { useState } from 'react'

export interface UseEulaServiceProps {
  host?: string
  tokenFactory?: () => Promise<string>
}

//component gets a list of claims from EID with the authority and a token
export const useEulaService = ({ host, tokenFactory }: UseEulaServiceProps) => {
  const [eula, setEula] = useState<string>()
  const [error, setError] = useState<string>()
  const [eulas, setEulas] = useState<string[]>()

  //check if we have eula that needs to be signed
  const getEulasNotSigned = async () => {
    setError('')
    if (host === undefined || tokenFactory === undefined) {
      setError('Something Went Wrong')
      return
    }
    const token = await tokenFactory()
    //if we have a host and token then do out and hit the userEula/id/required endpoint to look for unsigned eula
    const response = await fetch(`${host}/api/myeulas/required`, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    if (response.ok) {
      var result = await response.json()
      //if there are results then we will need to sign
      if (result && result.length > 0) {
        var eulaList: Array<string> = []
        for (var i = 0; i < result.length; i++) {
          var obj = result[i]
          eulaList.push(obj.eulaId)
        }
        setEulas(eulaList)
      } else {
        //nothing to sign. user is authorized
        setEulas([])
      }
    } else {
      //error with request
      setError('Error: Could not reach server')
    }
  }

  const getEulaContent = async (eulaId: string) => {
    setError('')
    if (host === undefined || tokenFactory === undefined) {
      setError('Something Went Wrong')
      return
    }
    const token = await tokenFactory()
    //If user has a eula to sign then go out and get the html from content endpoint
    const getEulaBody = await fetch(`${host}/api/eulas/${eulaId}/content`, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    if (getEulaBody.ok) {
      var eulaBody = await getEulaBody.text()
      return eulaBody
    } else {
      //error with request
      setError('Error: Could not retrieve eula')
    }
  }

  const signEula = async (eulaId: string) => {
    if (host === undefined || tokenFactory === undefined) {
      setError('Something Went Wrong')
      return
    }
    const token = await tokenFactory()
    const sign = await fetch(`${host}/api/myeulas/accept/${eulaId}`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    if (sign.ok) {
      getEulasNotSigned()
    } else {
      //error with request
      setError('Error: Could not sign eula')
    }
  }

  return { getEulasNotSigned, getEulaContent, signEula, eulas, error }
}
